<template lang="html">

  <documents-card :documents="bookmarks" title="Je favoriete artikelen" />

</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import documentsCard from '../common/documents-card'

export default {
  name: 'bookmarks',
  data: function () {
    return {
      bookmarks: []
    }
  },
  methods: {
    getBookmarks: function () {
      this.$bus.$emit('loadingEvent', true)
      this.$api.get('me/bookmarks?include=tags').then((response) => {
        for (let bookmark of response.data.data) {
          this.bookmarks.push({
            id: bookmark.id,
            name: bookmark.name,
            average_rating: bookmark.average_rating,
            tags: bookmark.tags.data,
            updated_at: bookmark.updated_at })
        }
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    }
  },
  mounted: function () {
    this.getBookmarks()
  },
  components: {
    'documents-card': documentsCard
  }
}
</script>
