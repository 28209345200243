<template lang="html">
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        <span class="icon is-medium">
          <i class="mdi mdi-bell-outline has-text-grey"></i>
        </span>
        <span class="has-text-weight-light is-size-4">{{ title }}</span>
      </p>
    </header>
    <div class="card-content is-paddingless">
      <table class="table is-fullwidth" v-if="notifications.length > 0">
        <thead>
          <tr>
            <th><abbr title="Onderwerp">Onderwerp</abbr></th>
            <th><abbr title="Datum">Datum</abbr></th>
            <th><abbr title="Type">Type</abbr></th>
            <th><abbr title="Lees status">Lees status</abbr></th>
          </tr>
        </thead>
        <tbody>
          <router-link :to="'/notification/' + notification.id" tag="tr" class="has-pointer" v-for="notification in notifications" :key="notification.id">
            <td><strong>{{ notification.title }}</strong></td>            
            <td>{{ notification.created_at | moment("DD-MM-YYYY") }}</td>
            <td>
              <i class="mdi mdi-cellphone-iphone" v-if="notification.type === 'push'"></i>
              <i class="mdi mdi-email-outline" v-if="notification.type === 'mail'"></i>
            </td>
            <td>
              <i class="mdi mdi-check-all has-text-success" v-if="notification.is_viewed"></i>
              <i class="mdi mdi-check has-text-grey-lighter" v-if="!notification.is_viewed"></i>
            </td>
          </router-link>
        </tbody>
      </table>
    </div>

    <no-entity
      title='Geen berichten gevonden'
      icon="bell-outline"
      :count="notifications.length" />

  </div>
</template>

<script>

import noEntity from '../common/no-entity'

export default {
  name: 'notificationsCard',
  props: [
    'notifications',
    'title'
  ],
  mounted: function () {},
  components: {
    'no-entity': noEntity
  }
}
</script>
