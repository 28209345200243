<template lang="html">
  <nav class="navbar" role="navigation" aria-label="main navigation" v-show="isLoggedin">
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/">
        <img class="logo" :src="logoUrl" v-if="logoUrl">
      </router-link>

      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" @click="showNav = !showNav" :class="{ 'is-active': showNav }">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu" :class="{ 'is-active': showNav }">
      <div class="navbar-start">
        <router-link class="navbar-item" to="/">
          <span class="icon">
            <i class="mdi mdi-magnify"></i>
          </span>
          <span>Zoeken</span>
        </router-link>
        <router-link class="navbar-item" to="/categories">
          <span class="icon">
            <i class="mdi mdi-book-open-variant"></i>
          </span>
          <span>Bladeren</span>
        </router-link>
        <router-link class="navbar-item" to="/tags">
          <span class="icon">
            <i class="mdi mdi-book"></i>
          </span>
          <span>Themas</span>
        </router-link>
        <router-link class="navbar-item" to="/bookmarks">
          <span class="icon">
            <i class="mdi mdi-heart"></i>
          </span>
          <span>Mijn favorieten</span>
        </router-link>
        <router-link class="navbar-item" to="/changelog">
          <span class="icon">
            <i class="mdi mdi-alert-decagram"></i>
          </span>
          <span>Laatste wijzigingen</span>
        </router-link>
        <router-link class="navbar-item" to="/notifications">
          <span class="icon">
            <i class="mdi mdi-bell-outline"></i>
          </span>
          <span>Berichten</span>
        </router-link>

        <span v-show="isLoading" class="spinner"></span>

      </div>

      <div class="navbar-end">
        <router-link class="navbar-item" to="/me">
          <avatar
            :username="readerName"
            class="avatar"
            v-if="readerName"
            :size="35"
            :backgroundColor="avatarColor"
            color="#FFFFFF">
          </avatar> {{ readerName }}
        </router-link>
        <span class="navbar-item">
          <a class="button" v-on:click="logout()">
            <span class="icon is-small">
              <i class="mdi mdi-logout"></i>
            </span>
            <span>Uitloggen</span>
          </a>
        </span>
      </div>
    </div>

  </nav>
</template>

<style lang="scss">
  @import '../assets/scss/main.scss';
</style>

<script>

import Avatar from 'vue-avatar'
import * as Cookies from 'tiny-cookie'

export default {
  name: 'navbar',
  data: function () {
    return {
      reader: {
        name: ''
      },
      isLoading: false,
      isLoggedin: false,
      logoUrl: '',
      showNav: false
    }
  },
  methods: {
    toggleIsLoading: function (state) {
      this.isLoading = state
    },
    toggleLogout: function () {
      this.isLoggedin = false
    },
    setReader: function (reader) {
      this.reader = reader
      this.isLoggedin = true
      this.isLoading = false
      // compose logo url
      this.logoUrl = process.env.VUE_APP_API_URL + '/theme/logo/vector/web?access_token=' + Cookies.get('access_token')
    },
    logout: function () {
      this.$bus.$emit('askReaderLogoutEvent', true)
    }
  },
  mounted: function () {
    this.$bus.$on('loadingEvent', this.toggleIsLoading)
    this.$bus.$on('apiLoggedOutEvent', this.toggleLogout)
    this.$bus.$on('readerDataLoadedEvent', this.setReader)
  },
  computed: {
    readerName: function () {
      if (this.reader && this.reader.name) return this.reader.name

      return ''
    },
    avatarColor: function () {
      return this.$root.$data.theme.buttonColor
    }
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('loadingEvent')
  },
  components: {
    'avatar': Avatar
  }
}
</script>
