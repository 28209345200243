<template lang="html">
  <div class="container">

    <div class="columns">
      <div class="column">

        <router-link :to="/category/ + category.parent_id + urlScope" class="button is-medium" v-if="category.parent_id">
          <span class="icon">
            <i class="mdi mdi-arrow-left"></i>
          </span>
          <span>Terug</span>
        </router-link>

        <router-link :to="'/categories' + urlScope" class="button is-medium" v-if="!category.parent_id">
          <span class="icon">
            <i class="mdi mdi-arrow-left"></i>
          </span>
          <span>Terug</span>
        </router-link>

      </div>
      <div class="column">

        <scope-switch 
          wide-scope-text="Alle informatie"
          :wide-scope-url="'/category/' + category.id + '/global'" 
          wide-scope-icon="infinity"
          small-scope-text="Informatie voor jou"
          :small-scope-url="'/category/' + category.id" 
          small-scope-icon="account"
          :is-wide="global" />
          
      </div>
      <div class="column"></div>
    </div>

    <div class="columns" v-for="i in rowCount" v-bind:key="i">
      <div class="column is-one-quarter" v-for="category in categories.slice((i - 1) * 4, i * 4)"  v-bind:key="category.id">
        <router-link :to="'/category/' + category.id + urlScope">
          <div class="card">
            <div class="card-image" v-if="category.has_image">
              <figure class="image image is-2by1">
                <img :src="imageUrl(category)">
              </figure>
            </div>
            <div class="card-content has-text-centered">
              <span class="icon is-large">
                <i :class="'mdi mdi-' + category.icon + ' mdi-48px'" :style="{color: category.color}"></i>
              </span>
              <h1>{{ category.name }}</h1>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <div class="columns">
      <div class="column is-12">

				<entities-card :entities="entities" :title="'Informatie rondom ' + category.name" />

      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import entitiesCard from '../common/entities-card'
import scopeSwitch from '../common/scope-switch'
import * as Cookies from 'tiny-cookie'

export default {
  name: 'categories',
  data: function () {
    return {
      category: {},
      categories: [],
      documents: [],
      entities: [],
      urlScope: ''
    }
  },
  props: [
    'global'
  ],
  methods: {
    getCategoryPersonal: function (categoryId) {
      this.$bus.$emit('loadingEvent', true)
      this.$api.get('category/' + categoryId + '/personal?include=documents,entities').then((response) => {
        this.categories = []
        for (let category of response.data.data.descendants.data) {
          this.categories.push({
            id: category.id,
            name: category.name,
            icon: category.icon,
            color: category.color,
            has_image: category.has_image })
        }

        this.documents = []
        for (let document of response.data.data.documents.data) {
          this.documents.push({
            id: document.id,
            name: document.name,
            average_rating: document.average_rating,
            tags: document.tags.data,
            updated_at: document.updated_at })
        }

        this.entities = []
        for (let entity of response.data.data.entities.data) {
          this.entities.push({
            entity_type: entity.entity_type,
            type: entity.type,
            id: entity.id,
            name: entity.name,
            average_rating: entity.average_rating,
            tags: entity.tags.data,
            updated_at: entity.updated_at })
        }

        this.category = response.data.data

        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getCategory: function (categoryId) {
      this.$bus.$emit('loadingEvent', true)
      this.$api.get('category/' + categoryId + '?include=documents,entities').then((response) => {
        this.categories = []
        for (let category of response.data.data.descendants.data) {
          this.categories.push({
            id: category.id,
            name: category.name,
            icon: category.icon,
            color: category.color,
            has_image: category.has_image })
        }

        this.documents = []
        for (let document of response.data.data.documents.data) {
          this.documents.push({
            id: document.id,
            name: document.name,
            average_rating: document.average_rating,
            tags: document.tags.data,
            updated_at: document.updated_at })
        }

        this.entities = []
        for (let entity of response.data.data.entities.data) {
          this.entities.push({
            entity_type: entity.entity_type,
            type: entity.type,
            id: entity.id,
            name: entity.name,
            average_rating: entity.average_rating,
            tags: entity.tags.data,
            updated_at: entity.updated_at })
        }

        this.category = response.data.data

        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    imageUrl: function (category) {
      // handle archiving through entity abstraction layer
      var token = Cookies.get('access_token')
      return process.env.VUE_APP_API_URL + '/category/' + category.id + '/image/file/720/360?access_token=' + token + '&client=web'
    }
  },
  mounted: function () {
    if (this.global) {
      this.getCategory(this.$route.params.id)
      this.urlScope = '/global'
    } else {
      this.getCategoryPersonal(this.$route.params.id)
      this.urlScope = ''
    }
  },
  computed: {
    rowCount: function () {
      return Math.ceil(this.categories.length / 4)
    },
    switcherActiveStyle: function () {
      return { 
        'background-color': this.$root.$data.theme.backgroundColor, 
        'color': 'white',
        'border-color': this.$root.$data.theme.backgroundColor
      }
    }
  },
  watch: {
    '$route' (to) {
      if (!this.global) {
        this.getCategoryPersonal(to.params.id)
        this.urlScope = ''
      } else {
        this.getCategory(to.params.id)
        this.urlScope = '/global'
      }
    },
    'global': {
      // watch for global or personal results request
      handler: function (global) {
        if (global) {
          this.urlScope = '/global'
          this.getCategory(this.$route.params.id)
        } else {
          this.urlScope = ''
          this.getCategoryPersonal(this.$route.params.id)
        }
      }
    }
  },
  components: {
    'entities-card': entitiesCard,
    'scope-switch': scopeSwitch
  }
}
</script>
