<template lang="html">
  <div class="container">
    
    <scope-switch 
      entities-name="artikelen" 
      wide-scope-text="Alle informatie"
      wide-scope-url="/categories/global" 
      wide-scope-icon="infinity"
      small-scope-text="Informatie voor jou"
      small-scope-url="/categories" 
      small-scope-icon="account"
      :is-wide="global" />

    <div class="columns" v-for="i in rowCount" v-bind:key="i">
      <div class="column is-one-quarter" v-for="category in categories.slice((i - 1) * 4, i * 4)" v-bind:key="category.id">
        <router-link :to="/category/ + category.id + urlScope">
          <div class="card">
            <div class="card-image" v-if="category.has_image">
              <figure class="image image is-2by1">
                <img :src="imageUrl(category)">
              </figure>
            </div>
            <div class="card-content has-text-centered">
              <span class="icon is-large">
                <i :class="'mdi mdi-' + category.icon + ' mdi-48px'" :style="{color: category.color}"></i>
              </span>
              <h1>{{ category.name }}</h1>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <no-entity
      title="Geen categorieen gevonden"
      icon="book-open-variant"
      :count="categories.length" />

  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import noEntity from '../common/no-entity'
import scopeSwitch from '../common/scope-switch'
import * as Cookies from 'tiny-cookie'

export default {
  name: 'categories',
  data: function () {
    return {
      categories: [],
      urlScope: ''
    }
  },
  props: [
    'global'
  ],
  methods: {
    getCategoriesPersonal: function () {
      this.$bus.$emit('loadingEvent', true)
      this.$api.get('categories/personal').then((response) => {
        this.categories = []
        for (let category of response.data.data) {
          this.categories.push({
            id: category.id,
            name: category.name,
            icon: category.icon,
            color: category.color,
            has_image: category.has_image })
        }

        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    getCategories: function () {
      this.$bus.$emit('loadingEvent', true)
      this.$api.get('categories').then((response) => {
        this.categories = []
        for (let category of response.data.data) {
          this.categories.push({
            id: category.id,
            name: category.name,
            icon: category.icon,
            color: category.color,
            has_image: category.has_image })
        }

        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    imageUrl: function (category) {
      // handle archiving through entity abstraction layer
      var token = Cookies.get('access_token')
      return process.env.VUE_APP_API_URL + '/category/' + category.id + '/image/file/720/360?access_token=' + token + '&client=web'
    }
  },
  mounted: function () {
    if (this.global) {
      this.getCategories(this.$route.params.id)
      this.urlScope = '/global'
    } else {
      this.getCategoriesPersonal(this.$route.params.id)
      this.urlScope = ''
    }
  },
  computed: {
    rowCount: function () {
      return Math.ceil(this.categories.length / 4)
    },
    switcherActiveStyle: function () {
      return { 
        'background-color': this.$root.$data.theme.backgroundColor, 
        'color': 'white',
        'border-color': this.$root.$data.theme.backgroundColor
      }
    }
  },
  watch: {
    '$route' (to) {
      if (!this.global) {
        this.getCategoriesPersonal(to.params.id)
        this.urlScope = '/global'
      } else {
        this.getCategories(to.params.id)
        this.urlScope = ''
      }
    },
    'global': {
      // watch for global or personal results request
      handler: function (global) {
        if (global) {
          this.urlScope = '/global'
          this.getCategories(this.$route.params.id)
        } else {
          this.urlScope = ''
          this.getCategoriesPersonal(this.$route.params.id)
        }
      }
    }
  },
  components: {
    'no-entity': noEntity,
    'scope-switch': scopeSwitch
  }
}
</script>
