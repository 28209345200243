<template lang="html">
  <div id="document">

    <rate-modal
        :entity="this.document"
        :entityName="this.document.name" />

    <comment-modal
        :entity="this.document"
        :entityName="this.document.name" />

    <video-modal />

    <div class="container">
      <div class="columns">
        <div class="column is-12">
          <h1 class="has-text-weight-light title is-size-3">{{ document.name }}</h1>
          <h2 class="subtitle has-text-grey" v-if="categories.length > 0">{{ categories[0].name }}</h2>
          <div class="tags">
            <router-link :to="'/tag/' + tag.id" tag="span" class="tag is-primary has-pointer" v-for="tag in tags" :key="tag.id">{{ tag.name }}</router-link>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12">
          <a class="button is-medium" v-on:click="goBack()">
            <span class="icon">
              <i class="mdi mdi-arrow-left"></i>
            </span>
            <span>Terug</span>
          </a>

          <div class="field has-addons is-pulled-right">
            <div class="control">
              <div class="dropdown is-hoverable">
                <div class="dropdown-trigger">
                  <button class="button" aria-haspopup="true">
                    Download
                  </button>
                </div>
                <div class="dropdown-menu" role="menu">
                  <div class="dropdown-content">
                    <a class="dropdown-item" v-on:click="downloadDocument(document)">
                      <span class="has-text-weight-bold">PDF</span> document
                    </a>
                    <a class="dropdown-item" v-on:click="downloadDocumentWord(document)">
                      <span class="has-text-weight-bold">Word</span> document
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <p class="control">
              <a class="button" v-on:click="deleteBookmarkDocument(document)" v-if="document.is_bookmarked">
                <span class="icon">
                  <i class="mdi mdi-heart has-text-danger"></i>
                </span>
                <span>Verwijder uit favorieten</span>
              </a>
              <a class="button" v-on:click="bookmarkDocument(document)" v-if="!document.is_bookmarked">
                <span class="icon">
                  <i class="mdi mdi-heart-outline has-text-primary"></i>
                </span>
                <span>Maak favoriet</span>
              </a>
            </p>
            <p class="control">
              <a class="button" v-on:click="rateDocumentModal(document)">
                <span class="icon">
                  <i class="mdi mdi-star-outline has-text-primary"></i>
                </span>
                <span>Geef beoordeling</span>
              </a>
            </p>
            <p class="control">
              <a class="button" v-on:click="commentDocumentModal(document)">
                <span class="icon">
                  <i class="mdi mdi-comment-outline has-text-primary"></i>
                </span>
                <span>Geef commentaar</span>
              </a>
            </p>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-8">
          <div class="card">
            <div class="card-content" v-if="paragraphs.length > 0">

              <paragraphs
                :paragraphs="paragraphs"
                :global="globalDerived"
                 />
                 
            </div>
            <div class="card-content is-paddingless" v-if="paragraphs.length == 0">

              <no-entity
                title='Dit artikel bevat geen verdere inhoud'
                icon="format-align-left"
                :count="paragraphs.length" />

            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">
                <span class="has-text-weight-light is-size-4">Bijlages</span>
              </p>
            </header>
            <div class="card-content is-paddingless">
              <div v-if="attachments.length >= 1">
                <a class="panel-block" v-for="attachment in attachments" :key="'attachment-' + attachment.id" v-on:click="downloadAttachment(attachment)">
                  <span class="panel-icon is-medium">
                    <i class="mdi mdi-paperclip" aria-hidden="true"></i>
                  </span>
                  {{ attachment.name }}
                </a>
              </div>

              <no-entity
                title='Geen bijlages toegevoegd'
                icon="paperclip"
                :count="attachments.length" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../../assets/scss/main.scss';
</style>

<script>

import paragraphs from './childs/paragraphs'
import rateModal from '../common/rate-modal'
import commentModal from '../common/comment-modal'
import videoModal from '../common/video-modal'
import noEntity from '../common/no-entity'
import * as Cookies from 'tiny-cookie'

export default {
  name: 'document',
  data: function () {
    return {
      paragraphs: [],
      tags: [],
      attachments: [],
      categories: [],
      document: {},
      globalDerived: false
    }
  },
  props: [
    'global'
  ],
  methods: {
    getDocument: function (documentId) {
      this.$bus.$emit('loadingEvent', true)
      this.$api.get('document/' + documentId + '?include=paragraphs,attachments,tags,categories').then((response) => {
        this.paragraphs = []
        for (let paragraph of response.data.data.paragraphs.data) {
          this.paragraphs.push({
            id: paragraph.id,
            name: paragraph.name,
            attachment_id: paragraph.attachment_id,
            content: paragraph.content,
            is_personal: paragraph.is_personal,
            positions: paragraph.positions.data,
            has_image: paragraph.has_image })
        }

        this.tags = []
        for (let tag of response.data.data.tags.data) {
          this.tags.push({
            id: tag.id,
            name: tag.name })
        }

        this.categories = []
        for (let category of response.data.data.categories.data) {
          this.categories.push({
            id: category.id,
            name: category.name })
        }

        this.attachments = []
        for (let attachment of response.data.data.attachments.data) {
          this.attachments.push({
            id: attachment.id,
            name: attachment.name,
            type: attachment.type })
        }

        this.document = response.data.data

        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    downloadDocument: function (document) {
      // handle archiving through entity abstraction layer
      var token = Cookies.get('access_token')
      window.location.href = process.env.VUE_APP_API_URL + '/document/' + document.id + '/file?access_token=' + token + '&client=web'
    },
    downloadDocumentWord: function (document) {
      // handle archiving through entity abstraction layer
      var token = Cookies.get('access_token')
      window.location.href = process.env.VUE_APP_API_URL + '/document/' + document.id + '/fileword?access_token=' + token + '&client=web'
    },
    bookmarkDocument: function (document) {
      this.$bus.$emit('loadingEvent', true)
      let bookmarkData = { document_id: document.id }
      this.$api.post('me/bookmark', bookmarkData).then(() => {
        this.document.is_bookmarked = true

        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    deleteBookmarkDocument: function (document) {
      this.$bus.$emit('loadingEvent', true)
      this.$api.delete('me/bookmark/' + document.id).then(() => {
        this.document.is_bookmarked = false

        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.disabled = false
        this.$bus.$emit('apiErrorEvent', error)
      })
    },
    rateDocumentModal: function () {
      this.$bus.$emit('askEntityRateEvent', true)
    },
    rateDocument: function (document, rating) {
      // handle rating through entity abstraction layer
      this.$entity.rate('document', 'Artikel', document.id, rating)
    },
    commentDocumentModal: function () {
      this.$bus.$emit('askEntityCommentEvent', true)
    },
    commentDocument: function (document, comment) {
      // handle commenting through entity abstraction layer
      this.$entity.comment('document', 'Artikel', document.id, comment)
    },
    attachmentVideoModal: function (attachment) {
      this.$bus.$emit('askAttachmentVideoEvent', attachment)
    },
    downloadAttachment: function (attachment) {
      // handle archiving through entity abstraction layer
      if (attachment.type === 'video/mp4') {
        this.attachmentVideoModal(attachment)
      } else {
        var token = Cookies.get('access_token')
        window.location.href = process.env.VUE_APP_API_URL + '/attachment/' + attachment.id + '/file?access_token=' + token + '&client=web'
      }
    },
    downloadProcess: function (entity) {
      // handle archiving through entity abstraction layer
      var token = Cookies.get('access_token')
      window.location.href = process.env.VUE_APP_API_URL + '/process/' + entity.id + '/file?access_token=' + token + '&client=web'
    },
    goBack: function () {
      this.$router.go(-1)
    }
  },
  mounted: function () {
    this.getDocument(this.$route.params.id)

    // listen for rating event
    this.$bus.$on('askEntityRatingEvent', (document, rating) => this.rateDocument(document, rating))
    // listen for commenting event
    this.$bus.$on('askEntityCommentingEvent', (document, comment) => this.commentDocument(document, comment))
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askEntityRatingEvent')
    this.$bus.$off('askEntityCommentEvent')
  },
  computed: {
    globalParagraphCount: function () {
      let count = 0

      for (let index in this.paragraphs) {
        if (!this.paragraphs[index].is_personal) count++
      }

      return count
    }
  },
  watch: {
    'global': {
      // watch for global or personal results request
      handler: function (global) {
        if (global) {
          this.globalDerived = true
        } else {
          this.globalDerived = false
        }
      }
    }
  },
  components: {
    'paragraphs': paragraphs,
    'rate-modal': rateModal,
    'comment-modal': commentModal,
    'video-modal': videoModal,
    'no-entity': noEntity
  }
}
</script>
