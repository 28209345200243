<template lang="html">
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        <span class="icon is-medium">
          <i class="mdi mdi-file-document has-text-grey"></i>
        </span>
        <span class="has-text-weight-light is-size-4">{{ title }}</span>
      </p>
    </header>
    <div class="card-content is-paddingless">
      <table class="table is-fullwidth" v-if="documents.length > 0">
        <thead>
          <tr>
            <th><abbr title="Naam">Naam</abbr></th>
            <th><abbr title="Beoordeling">Gemiddelde beoordeling</abbr></th>
            <th><abbr title="Thema">Thema's</abbr></th>
            <th><abbr title="Laatste wijziging">Laatste wijziging</abbr></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <router-link :to="'/document/' + document.id" tag="tr" class="has-pointer" v-for="document in documents" :key="document.id">
            <td>
              {{ document.name }}
            </td>
            <td>
              <star-rating
                :rating=document.average_rating
                :show-rating=false
                :star-size=25
                :read-only=true />
            </td>
            <td>
              <div class="tags">
                <router-link :to="'/tag/' + tag.id" tag="span" class="tag has-text-white has-pointer" :style="{backgroundColor: tag.color}" v-for="tag in document.tags" :key="tag.id">{{ tag.name }}</router-link>
              </div>
            </td>
            <td>{{ document.updated_at | moment("DD-MM-YYYY") }}</td>
            <td></td>
          </router-link>
        </tbody>
      </table>
    </div>

    <no-entity
      title='Geen artikelen gevonden'
      icon="file-document"
      :count="documents.length" />

  </div>
</template>

<script>

import noEntity from '../common/no-entity'
import StarRating from 'vue-star-rating'

export default {
  name: 'documentsCard',
  props: [
    'documents',
    'title'
  ],
  components: {
    'star-rating': StarRating,
    'no-entity': noEntity
  }
}
</script>
