<template lang="html">
  <article class="message" v-if="count === 0">
    <div class="message-body has-text-centered">
      <div class="content">
        <span class="icon is-large">
          <i :class="`mdi mdi-${icon} mdi-48px as-text-grey`"></i>
        </span>
        <h1 class="is-size-4 has-text-weight-light">{{title}}</h1>
        <p>{{message}}</p>
      </div>
      <router-link class="button is-outlined" :to="createPath" v-if="createPath">
        <span class="icon">
          <i class="mdi mdi-plus-circle-outline"></i>
        </span>
        <span>{{button}}</span>
      </router-link>
    </div>
  </article>
</template>

<script>

export default {
  name: 'noEntity',
  props: [
    'title',
    'button',
    'icon',
    'message',
    'createPath',
    'count'
  ]
}
</script>
