// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VueMoment from 'vue-moment'
import Notifications from 'vue-notification'
import vSelect from 'vue-select'
import navbar from './components/navbar'
import App from './App'
import router from './router'
import VTooltip from 'v-tooltip'
import VueCookie from 'vue-cookie'
import Api from './api'
import Entities from './entities'
import './filters/pretty-bytes'

// define a global event bus
Vue.prototype.$bus = new Vue()

Vue.use(VueCookie)
Vue.use(VueMoment)
Vue.use(vSelect)
Vue.use(Notifications)
Vue.use(VTooltip)

Vue.use(Api, {
  url: process.env.VUE_APP_API_URL,
  clientId: 3,
  clientSecret: 'EBUpHGLIDZKOCjZqcAun7e0SlKGhFzLjNSthrN7M',
  provider: 'readers',
  scope: '',
  accessTokenExpireDays: process.env.VUE_APP_API_TOKEN_EXPIRE_DAYS,
  refreshTokenExpireDays: process.env.VUE_APP_API_REFRESH_TOKEN_EXPIRE_DAYS,
  cookieSecure: process.env.VUE_APP_COOKIE_SECURE
})

Vue.use(Entities)

new Vue({
  render: App.render,
  router,
  data: function () {
    return {
      theme: {
        backgroundColor: 'black'
      },
      initialUrlPath: null
    }
  },
  components: {
    'navbar': navbar
    // 'extend-login-modal': extendLoginModal
  },
  methods: {
    showApiErrorNotification: function () {
      this.$notify({
        type: 'danger',
        title: 'Er ging iets mis',
        text: 'Het ophalen en/of opslaan van informatie is mislukt. Probeer je actie opnieuw uit te voeren.'
      })
    },
    logoutReaderRemote: function () {
      this.$api.logout()
    },
    logoutReader: function () {
      // clear localstorage
      localStorage.clear()
      // forward to login
      router.push('/login')
    },
    loginReader: function (credentials) {
      this.$api.login(credentials.username, credentials.password)
    },
    forwardReader: function () {
      // forward to dashboard if the initial url path is login
      if (this.initialUrlPath === '/login') {
        router.push('/')
      } else {
        router.push(this.initialUrlPath)
      }
    },
    extendLoginReader: function () {
      this.$api.extend()
    },
    getReaderInfo: function () {
      // first get theme colors
      this.getThemeColors()

      // second get reader info
      this.$bus.$emit('loadingEvent', true)
      this.$api.get('me').then((response) => {
        // create reader object
        let reader = {}
        reader.accountId = response.data.data.account_id
        reader.readerId = response.data.data.id
        reader.name = response.data.data.name
        // compose list of positions
        let positions = response.data.data.positions.data
        reader.positions = ''
        for (let key in positions) {
          reader.positions += positions[key].name + ', '
        }
        // write to localstorage
        localStorage.setItem('accountId', reader.accountId)
        localStorage.setItem('readerId', reader.readerId)
        localStorage.setItem('name', reader.name)
        localStorage.setItem('positions', reader.positions.replace(/,\s*$/, ''))
        // emit event
        this.$bus.$emit('loadingEvent', false)
        this.$bus.$emit('readerDataLoadedEvent', reader)
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.$bus.$emit('apiErrorEvent', error)
      })

      // forward to reader
      this.forwardReader()
    },
    getThemeColors: function () {
      this.$bus.$emit('loadingEvent', true)
      this.$api.get('theme/colors').then((response) => {
        // manually set the body style background color
        document.body.style.backgroundColor = response.data.web_background_color

        this.theme.backgroundColor = response.data.web_background_color
        this.theme.buttonColor = response.data.web_button_color
        
        // write theme info to localstorage
        localStorage.setItem('backgroundColor', response.data.web_background_color)
        localStorage.setItem('navColor', response.data.web_nav_color)
        localStorage.setItem('buttonColor', response.data.web_button_color)
        // emit event
        this.$bus.$emit('loadingEvent', false)
        this.$bus.$emit('themeDataLoadedEvent')
      }, (error) => {
        this.$bus.$emit('loadingEvent', false)
        this.$bus.$emit('apiErrorEvent', error)
      })
    }
  },
  created: function () {
    // set the initial url path (used for forwarding)
    this.initialUrlPath = this.$route.path

    // set event bus to api plugin
    this.$api.setEventBus(this.$bus)
    
    // try to authenticate with existing session
    if (this.$api.authenticate()) {
      // after succesfull login, get reader info, set theme colors and forward
      this.getReaderInfo()
      this.getThemeColors()
      this.forwardReader()
    }
  },
  mounted: function () {
    // instantiate entites plugin
    this.$entity.setApi(this.$api)
    this.$entity.setEventBus(this.$bus)
    this.$entity.setNotificationBus(this.$notify)

    // set correct momentjs locale
    this.$moment.locale('nl')

    // listen for a generic api connection error
    this.$bus.$on('apiErrorEvent', this.showApiErrorNotification)
    // listen for logout event
    this.$bus.$on('askReaderLogoutEvent', this.logoutReaderRemote)
    this.$bus.$on('askReaderLogoutEvent', this.logoutReader)
    // listen for session extend event
    this.$bus.$on('askReaderExtendLoginEvent', this.extendLoginReader)
    // listen for login event
    this.$bus.$on('askReaderLoginEvent', this.loginReader)
    // listen for password forgot event
    this.$bus.$on('askReaderPasswordForgotEvent', this.passwordForgotReader)
    // listen for api logged out event
    this.$bus.$on('apiLoggedOutEvent', this.logoutReader)
    // listen for api logged out event
    this.$bus.$on('apiLoggedInEvent', this.getReaderInfo)
    // listen for api invalid client event
    this.$bus.$on('apiInvalidClientEvent', this.showApiErrorNotification)
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('apiErrorEvent')
    this.$bus.$off('askReaderLogoutEvent')
    this.$bus.$off('askReaderLoginEvent')
    this.$bus.$off('askReaderPasswordForgotEvent')
    this.$bus.$off('apiLoggedOutEvent')
    this.$bus.$off('apiLoggedInEvent', this.getReaderInfo)
    this.$bus.$off('apiInvalidClientEvent')
  }
}).$mount('#app')
