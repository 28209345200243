<template lang="html">
  <multi-select
    v-model="selection"
    id="ajax"
    ref="search"
    label="name"
    track-by="id"
    placeholder="Type om te zoeken..."
    selectLabel=""
    open-direction="bottom"
    :options="documents"
    :multiple="true"
    :searchable="true"
    :internal-search="false"
    :clear-on-select="false"
    :close-on-select="false"
    :options-limit="300"
    :loading="loading"
    :limit="3"
    :max-height="600"
    :show-no-results="true"
    :show-no-options="false"
    :hide-selected="true"
    @search-change="inputSearch"
    @select="goToResult"
    class="search">
    <template slot="option" slot-scope="props">
      <div class="columns is-gapless result">
        <div class="column is-8">
          <router-link :to="'/document/' + props.option.id" tag="a" class="has-text-dark">
              <h1 class="has-text-weight-light title is-size-5">{{ props.option.name }}</h1>
              <h2 class="subtitle is-size-7" v-if="props.option.categories.length > 0">{{ props.option.categories[0].name }}</h2>
          </router-link>
        </div>
        <div class="column is-4">
          <star-rating
            :rating=props.option.average_rating
            :show-rating=false
            :star-size=25
            :read-only=true />
        </div>
      </div>
    </template>
    <no-entity-autocomplete
        title="Geen zoekresultaten"
        message="Probeer andere zoektermen."
        icon="magnify"
        slot="noResult"
        v-if="!loading" />
    <no-entity-autocomplete
        title="Geen zoekresultaten"
        message="Probeer andere zoektermen."
        icon="magnify"
        slot="noOptions"
        v-if="!loading" />
  </multi-select>
</template>

<style lang="scss">
  @import '~vue-multiselect/dist/vue-multiselect.min.css';
</style>

<script>

import _ from 'lodash'
import Multiselect from 'vue-multiselect'
import StarRating from 'vue-star-rating'
import noEntityAutocomplete from './no-entity-autocomplete'

export default {
  name: 'search',
  data () {
    return {
      documents: [],
      keywords: '',
      selection: [],
      result: [],
      loading: false
    }
  },
  methods: {
    getLabel (item) {
      return item.name
    },
    inputSearch: function (keywords) {
      this.typing = true
      this.keywords = keywords
    },
    getSearch: function (text) {
      // only search with at least some text
      if (text.length > 0) {
        this.$bus.$emit('loadingEvent', true)
        this.loading = true

        let searchData = {
          keywords: text
        }

        this.$api.post('search', searchData).then((response) => {
          this.documents = []
          for (let document of response.data.data) {
            this.documents.push({
              id: document.id,
              name: document.name,
              average_rating: document.average_rating,
              tags: document.tags.data,
              categories: document.categories.data,
              updated_at: document.updated_at })
          }

          this.$bus.$emit('loadingEvent', false)
          this.loading = false
        }, (error) => {
          this.$bus.$emit('loadingEvent', false)
          this.loading = false
          this.$bus.$emit('apiErrorEvent', error)
        })
      } else {
        // empty result set on empty search text
        this.documents = []
      }
    },
    goToResult: function (document) {
      this.$router.push({ name: 'document', params: { id: document.id } })
    }
  },
  mounted: function () {
    // this.$refs.search.$el.focus()
  },
  watch: {
    keywords: _.debounce(function () {
      this.typing = false
      // perform a search
      this.getSearch(this.keywords)
    }, 500)
  },
  components: {
    'multi-select': Multiselect,
    'star-rating': StarRating,
    'no-entity-autocomplete': noEntityAutocomplete
  }
}
</script>
