<template lang="html">
  <div class="modal" v-bind:class="{ 'is-active': visible }">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box">
        <p>Geef een beoordeling voor <strong>{{ entityName }}</strong> door het gewenste aantal sterren te kiezen.</p>
        <hr />
        <star-rating v-model="rate"
          :show-rating=false
          :star-size=35 />
        <hr />

        <generic-button 
          text="Beoordeling geven" 
          icon="star-outline"
          @click="askRating(entity)" 
          :disabled="rating" />

        <a class="button ml-2" v-on:click="close()">
          <span class="icon">
            <i class="mdi mdi-cancel"></i>
          </span>
          <span>Annuleer</span>
        </a>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" v-on:click="close()"></button>
  </div>
</template>

<script>

import StarRating from 'vue-star-rating'
import genericButton from '../common/generic-button'

export default {
  name: 'rateModal',
  props: [
    'entity',
    'entityName'
  ],
  data: function () {
    return {
      rating: false,
      rate: 0,
      visible: false
    }
  },
  methods: {
    askRating: function (entity) {
      this.$bus.$emit('askEntityRatingEvent', entity, this.rate)
    },
    close: function () {
      this.visible = false
    },
    spawnModal: function () {
      this.visible = true
    },
    setRating: function (state) {
      this.rating = state
      if (state === false) {
        this.visible = false
      }
    }
  },
  mounted: function () {
    // listen for ask for modal event
    this.$bus.$on('askEntityRateEvent', this.spawnModal)
    this.$bus.$on('ratingEvent', (state) => this.setRating(state))
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askEntityRateEvent')
  },
  components: {
    'star-rating': StarRating,
    'generic-button': genericButton
  }
}
</script>
