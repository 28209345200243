<template lang="html">
  <div class="card">

    <header class="card-header">
      <p class="card-header-title">
        <span class="has-text-weight-light is-size-4">{{ title }}</span>
      </p>
    </header>
    <div class="card-content is-paddingless">
      <table class="table is-fullwidth" v-if="entities.length > 0">
        <thead>
          <tr>
            <th></th>
            <th><abbr title="Naam">Naam</abbr></th>
            <th><abbr title="Thema">Thema's</abbr></th>
            <th><abbr title="Laatste wijziging">Laatste wijziging</abbr></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr class="has-pointer" v-for="entity in entities" :key="entity.id" v-on:click="openEntity(entity)">
            <th>
              <i class="mdi mdi-paperclip has-text-grey" aria-hidden="true" v-if="entity.entity_type === 'attachment'" />
              <i class="mdi mdi-file-document has-text-grey" aria-hidden="true" v-if="entity.entity_type  === 'document'" />
            </th>
            <td>
              {{ entity.name }}
            </td>
            <td>
              <div class="tags">
                <router-link :to="'/tag/' + tag.id" tag="span" class="tag has-text-white has-pointer" :style="{backgroundColor: tag.color}" v-for="tag in entity.tags" :key="tag.id">{{ tag.name }}</router-link>
              </div>
            </td>
            <td>{{ entity.updated_at | moment("DD-MM-YYYY") }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>

    <no-entity
      title='Geen informatie gevonden'
      icon="file-document"
      :count="entities.length" />

    <video-modal />

  </div>
</template>

<script>

import videoModal from '../common/video-modal'
import noEntity from '../common/no-entity'
import * as Cookies from 'tiny-cookie'

export default {
  name: 'entitiesCard',
  props: [
    'entities',
    'title'
  ],
  methods: {
    attachmentVideoModal: function (attachment) {
      this.$bus.$emit('askAttachmentVideoEvent', attachment)
    },
    downloadAttachment: function (attachment) {
      if (attachment.type === 'video/mp4') {
        this.attachmentVideoModal(attachment)
      } else {
        var token = Cookies.get('access_token')
        window.location.href = process.env.VUE_APP_API_URL + '/attachment/' + attachment.id + '/file?access_token=' + token + '&client=web'
      }
    },
    openEntity: function (entity) {
      if (entity.entity_type === 'document') {
        this.$router.push({ name: 'document', params: { id: entity.id } })
      } else {
        this.downloadAttachment(entity)
      }
    }
  },
  mounted: function () {

  },
  components: {
    'no-entity': noEntity,
    'video-modal': videoModal
  }
}
</script>
