import axios from 'axios'
import * as Cookies from 'tiny-cookie'
import moment from 'moment'

const Api = {
  install (Vue, options) {
    // get parameters
    const {url, clientId, clientSecret, provider, scope, accessTokenExpireDays, refreshTokenExpireDays, cookieSecure} = options

    // instantiate axios client
    const api = axios.create({
      baseURL: url,
      timeout: 10000,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Client': 'web'
      },
      withCredentials: true
    })

    let eventBus
    // send out an event
    const emitEvent = function (event, payload) {
      if (eventBus) {
        eventBus.$emit(event, payload)
      }
    }

    // store tokens locally
    const storeTokens = function (accessToken, refreshToken) {
      // calculate access token expire timestamp
      let accessTokenExpireTimestamp = moment().add(accessTokenExpireDays, 'days').unix()
      // set cookie containing access token
      Cookies.set('access_token', accessToken, { expires: accessTokenExpireDays, secure: cookieSecure })
      // set cookie containing access token expire timestamp
      Cookies.set('access_token_expire', accessTokenExpireTimestamp, { expires: accessTokenExpireDays, secure: cookieSecure })
      // set cookie containing refresh token
      Cookies.set('refresh_token', refreshToken, { expires: refreshTokenExpireDays, secure: cookieSecure })
    }

    // set access token on axios client
    const setAccessToken = function (token) {
      api.defaults.headers.common['Authorization'] = 'Bearer ' + token
    }

    // get access token based on password grant
    const getAccessToken = function (username, password) {
      // compose the grant
      let grant = {}
      grant.grant_type = 'password'
      grant.client_id = clientId
      grant.client_secret = clientSecret
      grant.scope = scope
      grant.provider = provider
      grant.username = username
      grant.password = password

      return api.post('oauth/token', grant).then((response) => {
        if (response.data.access_token && response.data.refresh_token) {
          // store tokens
          storeTokens(response.data.access_token, response.data.refresh_token)
          // set header with access token on api client
          setAccessToken(response.data.access_token)
          // set emailaddress in local storage to be used for 2FA
          localStorage.setItem('email', username)

          return true
        }
      })
    }

    // refresh access token based on refresh token grant
    const refreshAccessToken = function () {
      // get refresh token from cookie
      let refreshToken = Cookies.get('refresh_token')
      // compose the grant
      let grant = {}
      grant.grant_type = 'refresh_token'
      grant.client_id = clientId
      grant.client_secret = clientSecret
      grant.scope = scope
      grant.provider = provider
      grant.refresh_token = refreshToken

      return api.post('oauth/token', grant).then((response) => {
        if (response.data.access_token && response.data.refresh_token) {
          // store tokens
          storeTokens(response.data.access_token, response.data.refresh_token)
          // set header with access token on api client
          setAccessToken(response.data.access_token)

          return true
        }
      })
    }

    // revoke access tokens from api
    const revokeAccessTokens = function () {
      // ask logout from api
      return api.get('logout').then((response) => {
        if (response.data.message === 'logout_success') {
          return true
        } else {
          return false
        }
      })
    }

    // remove local cookies
    const removeCookies = function () {
      Cookies.remove('access_token')
      Cookies.remove('access_token_expire')
      Cookies.remove('refresh_token')
    }

    // define global api prototype
    Vue.prototype.$api = api

    // set global eventbus to use
    Vue.prototype.$api.setEventBus = function (bus) {
      eventBus = bus
    }

    Vue.prototype.$api.login = function (username, password) {
      getAccessToken(username, password).then(result => {
        if (result === true) {
          // emit event
          emitEvent('apiLoggedInEvent')

          return true
        }
      })
    }

    // logout the user
    Vue.prototype.$api.logout = function () {
      revokeAccessTokens().then(result => {
        // remove cookies on success
        if (result === true) {
          removeCookies()
          // emit event
          emitEvent('apiLoggedOutEvent')

          return true
        }
      })
    }

    // try to authenticate
    Vue.prototype.$api.authenticate = function () {
      // check for existing cookies giving access
      let accessToken = Cookies.get('access_token')
      let accessTokenExpireTimestamp = parseInt(Cookies.get('access_token_expire'))
      let refreshToken = Cookies.get('refresh_token')
      let now = moment().unix()

      if (accessToken && accessTokenExpireTimestamp && refreshToken) {
        // check if current access token is still valid
        if (accessTokenExpireTimestamp > now) {
          // set header with access token on api client
          setAccessToken(accessToken)
          // access token valid, emit event
          emitEvent('apiLoggedInEvent')

          return true
        } else {
          // access token expired, remove cookies
          removeCookies()
          // emit event
          emitEvent('apiLoggedOutEvent')

          return false
        }
      } else {
        // no session, logut
        emitEvent('apiLoggedOutEvent')

        return false
      }
    }

    // extend the current access token lifetime
    Vue.prototype.$api.extend = function () {
      refreshAccessToken().then(result => {
        if (result === true) {
          // emit event
          emitEvent('apiExtendedLoginEvent')
        }
      })
    }

    // response interceptor for handling errors thrown by api
    api.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      if (error.response) {
        // request was made and the server responded with a status code out of the range of 2xx
        if (error.response.data.message) {
          // handle specific errors
          switch (error.response.data.message) {
            case 'invalid_credentials':
              // emit event
              emitEvent('apiInvalidCredentialsEvent')
              break

            case 'unauthorized':
              // emit event
              emitEvent('apiLoggedOutEvent')
              // remove cookies
              removeCookies()
              break

            case 'invalid_request':
              // emit event
              emitEvent('apiInvalidRequestEvent')
              break

            case '2fa_required':
              // emit event
              emitEvent('apiTwoFactorRequiredEvent')
              break

            case 'invalid_client':
              // emit event
              emitEvent('apiInvalidClientEvent')
              break
          }
        }
        return error.response
      } else if (error.request) {
        // request was made but no response was received
        // emit event
        emitEvent('apiErrorEvent', error)
        emitEvent('apiLoggedOutEvent')
      } else {
        // something happened in setting up the request
        // emit event
        emitEvent('apiErrorEvent', error)
        emitEvent('apiLoggedOutEvent')
      }
    })

    // request interceptor
    api.interceptors.request.use(function (request) {
      // check for existing cookies giving access
      let accessToken = Cookies.get('access_token')
      let accessTokenExpireTimestamp = parseInt(Cookies.get('access_token_expire'))
      let refreshToken = Cookies.get('refresh_token')
      let now = moment().unix()

      if (accessToken && accessTokenExpireTimestamp && refreshToken) {
        // check validity of authorization
        if (accessTokenExpireTimestamp - now < 86400) {
          // calculate due hours
          let dueHours = Math.round((accessTokenExpireTimestamp - now) / 3600)
          // token almost due, emit event
          emitEvent('apiAuthorizationDueEvent', dueHours)
        }
        if (accessTokenExpireTimestamp > now) {
          // send timestamp on request to prevent caching
          request.params = {
            rnd: new Date().getTime()
          }

          // token valid, continue request
          return request
        }
        if (accessTokenExpireTimestamp < now) {
          // access token expired, remove cookies
          removeCookies()
          // emit event
          emitEvent('apiLoggedOutEvent')
        }
      }
      return request
    }, function (error) {
      // emit event
      emitEvent('apiErrorEvent', error)
      emitEvent('apiLoggedOutEvent')
    })
  }
}

export default Api
