<template lang="html">
  <div class="modal" v-bind:class="{ 'is-active': visible }">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box">
        <p>Schrijf je commentaar op <strong>{{ entityName }}</strong> in onderstaand veld.</p>
        <textarea class="textarea" ref="comment" placeholder="Schrijf je commentaar" rows="10" v-model="comment" autofocus="autofocus"></textarea>
        <hr />

        <generic-button 
          text="Commentaar insturen" 
          icon="message-processing"
          @click="askCommenting(entity)" 
          :disabled="commenting" />

        <a class="button ml-2" v-on:click="close()">
          <span class="icon">
            <i class="mdi mdi-cancel"></i>
          </span>
          <span>Annuleer</span>
        </a>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" v-on:click="close()"></button>
  </div>
</template>

<script>

import genericButton from '../common/generic-button'

export default {
  name: 'commentModal',
  props: [
    'entity',
    'entityName'
  ],
  data: function () {
    return {
      commenting: false,
      comment: '',
      visible: false
    }
  },
  methods: {
    askCommenting: function (entity) {
      this.$bus.$emit('askEntityCommentingEvent', entity, this.comment)
      this.comment = ''
    },
    close: function () {
      this.visible = false
    },
    spawnModal: function () {
      this.visible = true
    },
    setCommenting: function (state) {
      this.commenting = state
      if (state === false) {
        this.visible = false
      }
    }
  },
  mounted: function () {
    // listen for ask for modal event
    this.$bus.$on('askEntityCommentEvent', this.spawnModal)
    this.$bus.$on('commentingEvent', (state) => this.setCommenting(state))
  },
  beforeDestroy () {
    // stop listening
    this.$bus.$off('askEntityCommentEvent')
  },
  components: {
    'generic-button': genericButton
  }
}
</script>
